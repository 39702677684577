import types from "../../statics/types";
import {languages} from "../../statics/langs/languages";
const initState = {
  lang: languages.FR
};

export default function generalReducer(preState = initState, action) {
  const { type, data } = action;

  switch (type) {
    case types.reduxTypes.SET_GENERAL:
      // console.log('set general with data: ', data)
      return { ...preState, ...data };
    default:
      return preState;
  }
}